// 首页
export const PATH_ROOT = '/';
export const PATH_INDEX = '/index';
export const PATH_HOME = '/home';
export const PATH_UNIT_PLAY = '/unitPlay';
export const PATH_UNIT_WORD = '/unitWord';
export const PATH_READ_WORD = '/readWord';
export const PATH_LISTERNER_WORD = '/listernerWord';
export const PATH_PAY = '/orderPay';
// user
export const PATH_LOGIN = '/login';
export const PATH_REGISTER = '/register';
export const DOWN_LOAD = '/downLoad';
export const PATH_MINE = '/mine';

// newBook
export const PATH_SELECT_BOOK = '/selectBook';
export const PATH_UNIT_LIST = '/unitList';
export const PATH_TRANSLATE = '/translate';
export const PATH_UP_READ = '/upRead';
export const PATH_UP_READ_DETAIL = '/upReadDetail';
export const PATH_EXTEND_WORD = '/extendWord';
export const PATH_EXTEND_WORD_DETAIL = '/extendWordDetail';
export const PATH_LISTEN_TRAIN = '/listenTrain';
export const PATH_CUSTOM_LISTEN = '/customListen';
export const PATH_SELECT_WORD = '/selectWord';
export const PATH_CUSTOM_INPUT = '/customInput';
export const PATH_TRAIN_READ_WORD = '/trainReadWord';

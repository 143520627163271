
import { Component, Vue, Prop } from 'vue-property-decorator';
// import {
//   PATH_LIGHT_course_web,
//   PATH_LEAD_NEWS,
//   PATH_course_web_INFO,
//   PATH_BACK_course_web,
//   PATH_TRAVEL_course_web,
//   PATH_MINE
// } from '@/router';
import ZmIcon from '../ZmIcon/index.vue';

@Component({
  name: 'AppFooter',
  components: {}
})
export default class App extends Vue {
  tabList1 = [
    // { name: '亮村', icon: 'moon', activeIcon: 'moon', path: PATH_LIGHT_course_web },
    // { name: '头条', icon: 'leadNews', activeIcon: 'leadNews', path: PATH_LEAD_NEWS },
    // { name: '我的', icon: 'person', activeIcon: 'person', path: PATH_MINE }
  ];
  tabList2 = [
    // { name: '村况', icon: 'home', activeIcon: 'home', path: PATH_course_web_INFO },
    // { name: '回村', icon: 'location', activeIcon: 'location', path: PATH_BACK_course_web },
    // { name: '游村', icon: 'navigation', activeIcon: 'navigation', path: PATH_TRAVEL_course_web }
    // { name: '我的', icon: 'person', activeIcon: 'person-fill', path: PATH_MINE }
  ];

  @Prop({ type: Number, default: 1 }) type; // 1 首页底部，2 村详情底部

  get tabList() {
    let list = [];
    switch (this.type) {
      case 1:
        list = [...this.tabList1];
        break;
      case 2:
        list = [...this.tabList2];
        break;
      default:
        list = [...this.tabList1];
        break;
    }
    return list;
  }

  // type = 2 时才有id
  get id() {
    return this.$route.query.id || '';
  }

  onClickTab(path) {
    // wx.miniProgram.navigateBack({ delta: 1 });
    // wx.miniProgram.postMessage({data: '初始化'});
    if (this.$route.path !== path && this.type === 2) {
      // 等于2时才有id
      this.$router.push({
        path: path,
        query: {
          id: this.id
        }
      });
    } else if (this.$route.path !== path) {
      this.$router.push(path);
    }
  }

  renderItem(h, item) {
    const itemProperty = {
      class: 'zm-appFooter__item zm-flex__1',
      on: {
        click: () => {
          this.onClickTab(item.path);
        }
      }
    };
    const iconProperty = { props: { iconClass: item.icon }, class: ['zm-appFooter__itemIcon'] };
    if (this.$route.path === item.path) {
      itemProperty.class += ' is-active';
      iconProperty.props.iconClass = item.activeIcon || item.icon;
    }
    const Icon = h(ZmIcon, iconProperty);
    const P = h('p', item.name);
    return h('div', itemProperty, [Icon, P]);
  }
  mounted() {}

  render(h) {
    const property = { class: 'zm-appFooter zm-flex' };
    const Children = this.tabList.map((item) => this.renderItem(h, item));
    return h('div', property, Children);
  }
}

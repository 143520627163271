<!--ZmPopup 弹出层 -->
<template>
  <div>
    <van-popup
      v-model="show"
      v-bind="$attrs"
      :closeable="closeable"
      :get-container="getContainer"
      :close-on-click-overlay="closeOnClickOverlay"
      class="ZmPopup"
      v-on="listeners"
      :class="plugClass"
    >
      <slot />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'ZmPopup',
  props: {
    // 是否默认背景白色
    hasBg: {
      type: Boolean,
      default: true
    },
    value: {
      type: Boolean,
      required: true
    },
    closeable: {
      type: Boolean,
      default: true
    },
    getContainer: {
      type: [String, Function],
      default: 'body'
    },
    closeOnClickOverlay: {
      type: Boolean,
      default: false
    },

    extClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  computed: {
    show: {
      set(v) {
        this.$emit('input', v);
      },
      get() {
        return this.value;
      }

    },

     plugClass() {
       const plugClass = this.extClass + ' ' + (this.hasBg ? '' : 'noBg');
        return plugClass;
      },

    // 监听所有绑定事件
    listeners() {
      const listeners = {};
      const $listeners = this.$listeners;
      const exclude = ['input'];
      for (const i in $listeners) {
        if (!exclude.includes(i)) {
          listeners[i] = $listeners[i];
        }
      }
      return listeners;
    }
  },

  methods: {
    // 初始化方法
    init() {}
  }
};
</script>
<style lang="scss">
.noBg {
  background: none;
}
.ZmPopup {
  &.van-popup--center.van-popup--round {
    border-radius: 0.2rem;
  }
}
</style>

import router from './';
import nprogress from '@/utils/nprogress';
import qs from 'qs';
// import { appSetToken } from '@/utils/src/utilsApp';
import {PATH_UNIT_PLAY,PATH_UNIT_WORD,PATH_READ_WORD,PATH_HOME} from '@/router/routes/routePath';
import * as auth from '@/utils/auth';
const whiteList = [PATH_UNIT_PLAY,PATH_UNIT_WORD,PATH_READ_WORD,PATH_HOME];
router.beforeEach(async(to, from, next) => {
  try {
    console.log(whiteList.includes(to.path));
    if (whiteList.includes(to.path)) {
      await auth.fetchOperationLog(to.meta.title);
    }
    next();
  } catch (error) {
    console.log(error);
    next();
  }
});

router.onError(error => {
  const pattern = /chunk (.)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});

router.afterEach((to, from) => {
  document.title = to.meta && to.meta.title ? to.meta.title : '';
  let pathObj = to.query;
  if (pathObj.token) delete pathObj.token;
  const pathSearch = qs.stringify(pathObj);
  nprogress.done();
});
import * as path from './routePath';
export default [
  {
    path: path.PATH_LOGIN,
    meta: { title: '登录', keep: true },
    component: () => import('@/pages/login/index.vue')
  },

  {
    path: path.PATH_MINE,
    name: 'mine',
    meta: { title: '个人中心', keep: true },
    component: () => import('@/pages/mine/index.vue')
  }
];
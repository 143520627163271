/**
 * 拆解url，得到url的各个部分
 * @param url URL
 */
export default url => {
    let a = document.createElement('a');
    a.href = url;
    return {
      source: url,
      protocol: a.protocol.replace(':', ''),
      host: a.host,
      port: a.port,
      hostname: a.hostname,
      origin: a.origin,
      query: a.search,
      params: (function () {
        let ret = {};
        let searchString = a.search.replace(/^\?/, '').split('&');
        for (let i = 0, len = searchString.length; i < len; i++) {
          if (searchString[i]) {
            let keyValue = searchString[i].split('=');
            ret[keyValue[0]] = keyValue[1];
          }
        }
        return ret;
      })(),
      file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],
      hash: a.hash,
      path: a.pathname.replace(/^([^\/])/, '/$1'),
      uri: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],
      segments: a.pathname.replace(/^\//, '').split('/')
    };
  };

'use strict';

// app
const app = {
  UPDATE_USER_INFO: 'app_update_user_info',
  SHOW_IMAGES_DIALOG: 'show_images_dialog',
  SET_FAMOUS_INDEX: 'set_famous_index',
  SET_APP_INFO: 'set_app_info',
  DESTROY_ACCOUNT: 'destroy_account'
};
const course = {
  CURRENT_BOOK: 'current_book',
  HOME_TAB: 'home_tab',
  UNIT_INDEX: 'unit_index',
  UNIT_LIST: 'unit_list',
  WORD_LIST: 'word_list',
  PAGE_CONTENT_LIST: 'PAGE_CONTENT_LIST',
  READING: 'READING'
};
const listen = {
  CUSTOM_LISTEN_DATA: 'custom_listen_data',
  LISTEN_BOOK: 'listen_book',
  LISTEN_BOOK_UNIT: 'listen_book_unit',
  LISTEN_WORD_LIST: 'listen_word_list'
};
// udpate
const update = {
  UPDATE: 'update_update',
  CLEAR: 'update_clear'
};

export { app, update, course, listen };

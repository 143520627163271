
































import { page } from 'store2';
import { Component, Vue, Prop, Emit, Mixins, Watch, Inject, Provide, PropSync } from 'vue-property-decorator';

@Component({
  name: 'zmList',
  components: {}
})
export default class App extends Vue {
  listData = [];
  isLoading = false;
  isFinished = false;
  isError = false;
  total = 0;
  searchParams = {
    pageNo: 0,
    pageSize: 10
  };

  // 最少参数可以只传：getList
  // getList = function (params){ return api.getList(params) }
  @Prop({ type: String, default: 'down' }) direction;
  @Prop({ type: Boolean, default: true }) immediateCheck;
  @Prop({ type: Number, default: 30 }) offset;
  @Prop({ type: String }) loadingText;
  @Prop({ type: String }) finishedText;
  @Prop({ type: String }) errorText;
  @Prop({ type: String, default: 'pt40 pb20' }) noDataClass;
  @Prop({ type: String }) noDataType;
  @Prop({ type: String }) noDataDesc;
  @Prop({ type: Function, required: true }) getList;
  @Prop({
    type: Object,
    default() {
      return {};
    }
  })
  extraParams;

  get isNoData() {
    return this.isFinished && (!this.listData || !this.listData.length);
  }

  onLoad() {
    this.getListHandler();
  }

  // 提供从第一页开始查询，通过ref调用
  onSearch() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.searchParams.pageNo = 0;
    this.$nextTick(this.getListHandler);
  }

  // 提供一次性刷新数据，通过ref调用，可用于下拉刷新
  onRefresh() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    const rows = this.searchParams.pageNo * this.searchParams.pageSize;
    const params = Object.assign({}, this.extraParams, { pageNo: 0, pageSize: rows });
    return this.getList(params)
      .then((res) => {
        this.listData = res.data || [];
        this.isLoading = false;
      })
      .catch((e) => {
        this.isLoading = false;
      });
  }

  getListHandler() {
    const _this = this;
    const params = Object.assign({}, this.extraParams, this.searchParams);
    const { pageNo, pageSize } = params;
    this.getList(params)
      .then((res) => {
        const { data: list, totalRecordCount } = res;
        // listData 赋值
        if (pageNo === 0) {
          this.listData = list || [];
        } else {
          this.listData = this.listData.concat(list || []);
        }
        // isFinished & page 赋值
        if (totalRecordCount > this.listData.length) {
        console.log(list.length,this.listData.length,totalRecordCount);
          this.isFinished = false;
          this.searchParams.pageNo += 1;
          // this.isFinished = true;
        } else {
          this.isFinished = true;
        }
        this.isLoading = false;
        this.total = totalRecordCount;
        return res;
      })
      .catch((e) => {
        if (pageNo === 1) {
          this.listData = [];
        }
        this.isError = true;
        this.isLoading = false;
      });
  }

  created() {}
  mounted() {}
}

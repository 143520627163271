import * as path from './routePath';
export default [
  {
    path: path.PATH_HOME,
    meta: { title: '首页', keep: true },
    component: () => import('@/pages/index/index.vue')
  },
  {
    path: path.PATH_UNIT_PLAY,
    meta: { title: '单元详情', keep: true },
    component: () => import('@/pages/unitPlay/index.vue')
  },
  {
    path: path.PATH_UNIT_WORD,
    meta: { title: '单元练习', keep: true },
    component: () => import('@/pages/unitWord/index.vue')
  },
  {
    path: path.PATH_READ_WORD,
    meta: { title: '单元跟读', keep: true },
    component: () => import('@/pages/unitWord/read.vue')
  },
  {
    path: path.PATH_LISTERNER_WORD,
    meta: { title: '听写练习', keep: true },
    component: () => import('@/pages/unitWord/listerner.vue')
  },
  {
    path: path.PATH_PAY,
    meta: { title: '课程支付', keep: true },
    component: () => import('@/pages/orderPay/index.vue')
  },
  {
    path: path.PATH_SELECT_BOOK,
    meta: { title: '选择教材', keep: true },
    component: () => import('@/pages/selectBook/index.vue')
  },
  {
    path: path.PATH_UNIT_LIST,
    meta: { title: '选择单元', keep: true },
    component: () => import('@/pages/selectUnit/index.vue')
  },
  {
    path: path.PATH_TRANSLATE,
    meta: { title: '查看翻译', keep: true },
    component: () => import('@/pages/translate/index.vue')
  },
  {
    path: path.PATH_UP_READ,
    meta: { title: '提升阅读', keep: true },
    component: () => import('@/pages/upRead/index.vue')
  },
  {
    path: '/upReadDetail/:id',
    name: 'upReadDetail',
    meta: { title: '提升阅读', keep: true },
    component: () => import('@/pages/upRead/detail.vue')
  },
  {
    path: path.PATH_EXTEND_WORD,
    name: 'extendWord',
    meta: { title: '扩展词汇', keep: true },
    component: () => import('@/pages/extendWord/index.vue')
  },
  {
    path: path.PATH_EXTEND_WORD_DETAIL,
    name: 'extendWordDetail',
    meta: { title: '扩展词汇', keep: true },
    component: () => import('@/pages/extendWord/detail.vue')
  },
  {
    path: path.PATH_LISTEN_TRAIN,
    name: 'listenTrain',
    meta: { title: '听力训练方法', keep: true },
    component: () => import('@/pages/listenTrain/index.vue')
  },
  {
    path: path.PATH_CUSTOM_LISTEN,
    name: 'customListen',
    meta: { title: '自定义听力配置', keep: true },
    component: () => import('@/pages/listenTrain/customListen.vue')
  },
  {
    path: path.PATH_SELECT_WORD,
    name: 'selectWord',
    meta: { title: '选择词汇', keep: true },
    component: () => import('@/pages/listenTrain/selectWord.vue')
  },
  {
    path: path.PATH_CUSTOM_INPUT,
    name: 'customInput',
    meta: { title: '自定义听力配置', keep: true },
    component: () => import('@/pages/listenTrain/customInput.vue')
  },
  {
    path: path.PATH_TRAIN_READ_WORD,
    name: 'trainReadWord',
    meta: { title: '听力训练方法', keep: true },
    component: () => import('@/pages/listenTrain/trainReadWord.vue')
  }
];

import {hex_md5} from '@/utils/md5';
function uuid() {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23];

    var uuid = s.join("");
    return uuid;
}
export default (filename) => {
    var timestamp = new Date().getTime();
    // 3 固定密钥
    var private_key = "836997375c81469887925995576066da";
    // 4 生成一个随机数
    var rand = uuid();
    // 5 拼接加密字符串
    var sign = filename + "-" + timestamp + "-" + rand + "-" + private_key;
    // 6 MD5 加密
    return  {
        sign: hex_md5(sign),
        rand,
        timestamp
    };
};
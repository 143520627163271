function _mockItemGetter(i, id) {
  return {
    name: `name${id}`,
    id: id
  };
}

function mockGetList(params, total = 22, itemGetter = _mockItemGetter) {
  const result = {
    code: 1000,
    pagedata: [],
    totalcount: total
  };
  const { page, rows } = params;
  const _t = page * rows;
  const _l = _t > total ? total % rows : rows;
  const maxPage = Math.ceil(total / rows);
  if (page <= maxPage) {
    const index = page * rows;
    result.pagedata = new Array(_l).fill(1).map((o, i) => {
      const id = index + i;
      return itemGetter(i, id);
    });
  }

  return Promise.resolve(result);
}
export {
  mockGetList
};

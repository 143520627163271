function getFormatDate(time, formater = 'yyyy-MM-dd hh:mm:ss') {
  if (!time) {
    return '';
  }
  const date = new Date(time);
  const o = {
    'y+': date.getFullYear(),
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
    S: date.getMilliseconds()
  };

  for (const k in o) {
    if (Object.prototype.hasOwnProperty.call(o, k) && new RegExp('(' + k + ')').test(formater)) {
      const v = o[k] + '';
      const r1l = RegExp.$1.length;
      formater = formater.replace(RegExp.$1, r1l === 1 ? v : ('00' + v).substr(-r1l));
    }
  }
  return formater;
}

export { getFormatDate };

import axios from 'axios';
import { dataToUnderline, dataToHump, appGetToken } from '@/utils';
import { handleResponse, handleBlockError, _handleError } from './requestHandle';
import { showLoading, hideLoading } from './requestLoading';
import config from '@/config';
import store from '@/store';
import removeEmpty from '@/utils/src/removeEmpty';
import sign from './sign';
// const BASE_URL = '/';
// import sign from './sign'
const TIMEOUT = 20000;
const HEADERS = {};
const axiosInstance = axios.create({
  baseURL: config.host,
  timeout: TIMEOUT,
  headers: { ...HEADERS },
  validateStatus(status) {
    // 所有的状态码当作成功对待，交给handlerResponse处理
    return status >= 200 && status < 300; // default;
  }
});
axiosInstance.interceptors.request.use(config => {
  let params = config.params || {};
  params = removeEmpty(params);
  // config.params = dataToUnderline(params);
  if (config.method.toLowerCase() !== 'get') {
    config.data = removeEmpty(config.data);
  }
  config.headers = { ...config.headers, ...sign(`${config.baseURL}${config.url}`) };
  return config;
});

axiosInstance.interceptors.response.use(
  response => {
    response.data = dataToHump(response.data);
    return response;
  },
  err => {
    return _handleError(err.response);
  }
);
/**
 *
 * @param axiosConfig axios 请求的配置
 * @param options 接口的其他配置
 */
const request = function(axiosConfig) {
  const loading = axiosConfig.loading !== false; // 默认设置为true
  if (loading) {
    showLoading();
  }
  try {
    // 添加认证请求头等逻辑

    const token = appGetToken();
    if (token) {
      axiosConfig.headers = {
        Authorization: `${token}`
      };
    }

    return axiosInstance(axiosConfig)
      .catch(e => {
        if (loading) {
          hideLoading();
        }
        return Promise.reject(e);
      })
      .then(res => {
        if (!res) return Promise.reject(new Error('无返回'));
        // 接口正常返回
        // 这里将options单独提取出来，是为了扩展性，也可以不提取
        const options = { preHandler: axiosConfig.preHandler };
        if (loading) {
          hideLoading();
        }
        return handleResponse(res, options);
      });
  } catch (e) {
    if (loading) {
      hideLoading();
    }
    handleBlockError(e);
    return Promise.reject({ code: -1, msg: '请求出错，请稍后再试' });
  }
};

request.get = function(url, params?: any, options?) {
  params = params || {};
  options = options || {};
  const _config = { url, ...options, params: params, method: 'get' };
  return request(_config);
};

request.delete = function(url, params?: any, options?) {
  params = params || {};
  options = options || {};
  const _config = { url, ...options, params: params, method: 'delete' };
  return request(_config);
};

request.post = function(url, data?: any, options?) {
  data = data || {};
  options = options || {};
  const _config = { url, ...options, data: data, method: 'post' };
  return request(_config);
};

request.put = function(url, data?: any, options?) {
  data = data || {};
  options = options || {};
  const _config = { url, ...options, data: data, method: 'put' };
  return request(_config);
};

export default request;

import wx from 'weixin-js-sdk';
import message from './message';
import { os } from './tools';
import { alertToast } from './utilsAlert';
import router from '@/router/index';
// import { PATH_NAVIGATION } from '../../router/routes/routePath';
/**
 * 分享
 * @param params
 * @param cb
 */

export function bridgeShare(params: any, cb?: () => void) {
  wx.miniProgram.postMessage({
    data: {
      name: 'share',
      ...params
    }
  });
  message.alert({ message: '请点击屏幕右上方...，进行转发分享' }).then(() => {
    if (typeof cb === 'function') {
      cb();
    }
  });
}

/**
 * 重启
 */

export function bridgeRelanch(code) {
  if (wx.miniProgram) {
    wx.miniProgram.postMessage({
      data: {
        type: 'unlogin',
        code
      }
    });
    wx.miniProgram.navigateBack({
      delta: 1 // 返回的页面数，如果 delta 大于现有页面数，则返回到首页,
    });
  } else {
    setTimeout(() => {
      if (wx.miniProgram && wx.miniProgram.relanch) {
        wx.miniProgram.postMessage({
          name: 'unlogin',
          message: '未登陆'
        });
        wx.miniProgram.relanch({
          url: '/index.html',
          fail() {
            console.log('小程序relanch 失败');
          }
        });
      }
    }, 10);
  }
}
/**
 * 导航
 * @param params
 * @param cb
 */

export function bridgeNavigation(params: any, cb?: () => {}) {
  const [lon, lat] = params.position || [];
  // router.push({
  //   path: PATH_NAVIGATION,
  //   query: { lon, lat }
  // });
}
export const wxH5Share = (data, wxConfigDesc) => {
  // let wx = window.wx || wx;
  if (wx) {
    wx.config({
      debug: data.debug,
      appId: data.appid,
      timestamp: data.timestamp,
      nonceStr: data.nonceStr,
      signature: data.signature,
      jsApiList: [
        // 所有要调用的 API 都要加到这个列表中
        'onMenuShareTimeline',
        'onMenuShareAppMessage'
      ]
    });
    // 完成wx.config，执行这里
    wx.ready(function() {
      // 分享到朋友圈
      wx.onMenuShareTimeline({
        ...wxConfigDesc,
        success: function() {
          // 分享成功执行此回调函数
          // alert('已分享朋友圈');
        },
        cancel: function() {
          alert('取消分享');
        }
      });

      // 分享给朋友
      wx.onMenuShareAppMessage({
        ...wxConfigDesc,
        trigger: function(res) {
          // 不要尝试在trigger中使用ajax异步请求修改本次分享的内容，因为客户端分享操作是一个同步操作，这时候使用ajax的回包会还没有返回
        },
        success: function(res) {
          // 分享成功执行此回调函数
          // alert('已分享');
        },
        cancel: function(res) {
          alert('已取消');
        },
        fail: function(res) {
          alert(JSON.stringify(res));
        }
      });
    });
  }
};

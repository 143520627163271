'use strict';
import store2 from '../../store2';
import { listen } from '../mutationType';
import * as storageKey from '@/config/static';
// import { codes } from '@/api/request/requestCode';
import bookApi from '@/api/src/book';
// import { alertConfirm } from '@/utils/src/utilsAlert';
// import router from '@/router';
// import * as path from '@/router/routes/routePath';
import * as auth from '@/utils/auth';
export default {
  namespaced: true,
  state: {
    data: store2.get(storageKey.LOC_CUSTOM_SELECT) || [],
    currentBook: store2.get(storageKey.LOC_LISTEN_BOOK) || {},
    currentUnit: store2.get(storageKey.LOC_LISTEN_BOOK_UNIT) || '',
    customWordList: []
  },
  mutations: {
    [listen.CUSTOM_LISTEN_DATA](state: any, data = []) {
      state.data = data;
      store2.set(storageKey.LOC_CUSTOM_SELECT, data);
    },
    [listen.LISTEN_BOOK](state: any, data = []) {
      state.currentBook = data;
      store2.set(storageKey.LOC_LISTEN_BOOK, data);
    },
    [listen.LISTEN_BOOK_UNIT](state: any, data = []) {
      state.currentUnit = data;
      store2.set(storageKey.LOC_LISTEN_BOOK_UNIT, data);
    },
    [listen.LISTEN_WORD_LIST](state: any, data = []) {
      state.customWordList = data;
      // store2.set(storageKey.LOC_LISTEN_BOOK_UNIT, data);
    }
  },
  actions: {
    setListenData({ commit }, data) {
      commit(listen.CUSTOM_LISTEN_DATA, data);
    },
    setListenBook({ commit }, data) {
      commit(listen.LISTEN_BOOK, data);
    },
    setListenUnit({ commit }, data) {
      commit(listen.LISTEN_BOOK_UNIT, data);
    },
    setCustomWordList({ commit }, data) {
      commit(listen.LISTEN_WORD_LIST, data);
    }
  }
};

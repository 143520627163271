export default {
  userInfo: state => (state.app.userInfo && state.app.userInfo.user) || {},
  appInfo: state => state.app.appInfo || {},
  token: state => state.app.userInfo && state.app.userInfo.accessToken,
  showImagesDialog: state => state.showImagesDialoog,
  famousIndex: state => state.app.famousIndex,
  updateInfo: state => state.update.updateInfo,
  currentBook: state => state.app.book,
  unitIndex: state => state.app.unitIndex,
  currentUnit: state => state.app.unitList.length && state.app.unitList[state.app.unitIndex],
  mainTab: state => state.app.mainTab,
  unitList: state => state.app.unitList,
  wordList: state => state.app.wordList,
  pageContentList: state => state.app.pageContentList,
  pageContentListLength: state => state.app.pageContentList.length,
  unitListLength: state => state.app.unitList.length,
  readingBook: state => state.app.reading,
  //
  listenData: state => state.listen.data,
  listenCurrentBook: state => state.listen.currentBook,
  listenCurrentUnit: state => state.listen.currentUnit,
  customWordList: state => state.listen.customWordList
};

export const tabbarList = [
    {
      name: '教材',
      value: 'Home',
      icon: 'home',
      activeIcon: 'home'
    },
    {
      name: '单元',
      value: 'Unit',
      icon: 'leadNews',
      activeIcon: 'leadNews'
    },
    {
      name: '会员中心',
      value: 'Mine',
      icon: 'person',
      activeIcon: 'person'
    }
  ];
export const cnNumber = [ '一', '二', '三', '四', '五', '六', '七', '八', '九'];
const semester = ['上','下'];
export const usingFlagList = () => {
  let res = [];
   res = semester.map((item,index) => {
    return {
      text: item + '册',
      value: index + 1
    };
  });
  res.unshift({
    text: '全部学期',
    value: ''
  });
  return res;
};
 export const gradeList = (list) => {
   let res = [];
   res = cnNumber.map((item,index) => {
     return {
       text: item + '年级',
       value: index + 1
     };
   });
   res.unshift({
    text: '全部年级',
    value: ''
  });
  return res;
 };
<!--ZmDatePick 月份选择 -->
<template>
  <div>
    <div @click="toShow">
      <slot v-bind="{showValue}">
        <van-cell :title="label" required :is-link="isLink" :value="showValue" />
      </slot>
    </div>
    <zm-popup v-model="show" position="bottom" :close-on-click-overlay="true" :closeable="false">
      <van-datetime-picker v-if="show" v-model="currentDate" :type="type" v-bind="$attrs" :min-date="minDate" :max-date="maxDate"
        @cancel="cancel" @confirm="onConfirm"
/>
    </zm-popup>
  </div>
</template>

<script>
/**
 * @confirm 选中
 *  v-model 双向绑定时间，  :value 不双向绑定
 *
 */
var currentYear = new Date().getFullYear();
import moment from 'dayjs';
export default {
  name: 'ZmDatePick',
  props: {
    format: {
      type: String
      // default: 'YYYY-MM-DD'
      // YYYY-MM-DD HH:mm:ss 返回的时间格式
    },

    label: String,
    placeholder: {
      type: String,
      default: '请选择'
    },
    isLink: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    // 日期
    value: {
      type: [String,Number],
      default: ''
    },

    type: {
      type: String,
      default: 'date'
    },

    // 标题名
    title: {
      type: String,
      default: ''
    },

    minDate: {
      data: Date,
      default() {
        return new Date('1920-01-01');
      }
    },
    maxDate: {
      type: Date,
      default: function _default() {
        return new Date(currentYear + 10, 11, 31);
      }
    }
  },
  data() {
    return {
      show: false,
      currentDate: new Date()
    };
  },
  computed: {
    showValue() {
      const value = '' + this.value;
      const placeholder = this.placeholder;
      return value || placeholder;
    },
    // 默认时间格式
    dfrt() {
      const format = this.format;
      const type = this.type;
      if (format) { return format; }
      if (type === 'year-month') { return 'YYYY-MM'; }
      return 'YYYY-MM-DD';
    }
  },

  methods: {
    // 初始化方法
    init() { },

    toShow() {
      this.handleTime();
      this.show = true;
    },
    handleTime() {
      const v = this.value;
      const time = moment(v).valueOf();
      this.currentDate = isNaN(time) ? new Date() : new Date(time);
    },

    cancel() {
      this.show = false;
    },

    onConfirm(v) {
      console.log(v);
      const value = moment(v).format(this.dfrt);
      this.currentDate = value;
      this.$emit('confirm', value);
      this.$emit('input', value);
      this.show = false;
    }
  }
};
</script>
<style lang="scss" scoped></style>

import * as path from './routePath';
import { lazyComponent } from '@/utils';

export const requireRoutesArray = (r, exinclude) => {
  let contents = [];
  const paths = r.keys().filter(p => {
    return exinclude.indexOf(p) === -1;
  });
  for (const p of paths) {
    const fn = r(p).default ? r(p).default : r(p);
    if (fn instanceof Array) {
      contents = [...contents, ...fn];
    } else if (fn instanceof Object) {
      contents.push(fn);
    }
  }
  return contents;
};

// meta: {
//   pageTitileShow: '1',
//   title: '主页',
//   isBack: '0',
//   isTabbar: '1'
// },
const content = requireRoutesArray(require.context('./', true, /^\.\/[\s\S]+\/*\.js$/), ['./index.js']);
console.log(content);
const routes: any[] = [
  {
    path: path.PATH_ROOT,
    redirect: path.PATH_INDEX,
    meta: { title: '' }
  },
  {
    path: path.PATH_INDEX,
    redirect: path.PATH_HOME,
    meta: { title: '' },
    component: lazyComponent('index'),
    children: [
    ]
  },
  ...content
];

const projMode = process.env.VUE_APP_FLAG;

// if (process.env.NODE_ENV === 'development') {
// if (projMode !== 'production') {
//   routes.push({
//     name: 'Dev',
//     path: '/dev',
//     component: () => import('@/pages/dev/index.vue')
//   });
// }

export default routes;

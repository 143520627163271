export function filterAge(time: any, endTime: any): number | string {
  if (!time) {
    return '';
  }
  const y1 = new Date(time).getFullYear();
  const y2 = endTime ? new Date(endTime).getFullYear() : new Date().getFullYear();
  return y2 - y1;
}

const W = 10000;
const Y = W * W;

export function filterLargeNumber(ipt: any, unit = '元') {
  let r: any = ipt;
  if (!ipt) {
    r = 0;
  } else {
    if (r > Y) {
      r = (r / Y).toFixed(1);
      r += '亿';
    } else if (r > W) {
      r = (r / W).toFixed(1);
      r += '万';
    }
    return r;
  }
  return `${r}${unit}`;
}

export function filterMoney(ipt: any) {
  return filterLargeNumber(ipt, '元');
}
export function filterFixed(input: number, num: number = 2): string {
  return (+input / 100).toFixed(num);
}
export function defaultSrc(v, src) {
  if (v) {
    return v;
  }
  return src || require('@/assets/img/default/defaultTX.png');
}
export {};

import { Toast } from 'vant';
import { Dialog } from 'vant';
import varColor from '@/styles/varHelper/_var.scss';
const mainColor = varColor.mainColor;

const showMsg = (message, type = 'text') => {
  Toast({ message, type, closeOnClick: true });
};

const msg = {
  showMsg,
  hide() {
    Toast.clear();
  },
  loading(message) {
    Toast({ message, type: 'loading', duration: 30000 });
  },
  alert(option = {}) {
    const d = {
      title: '温馨提示',
      confirmButtonColor: mainColor
    };
    const data = { ...d, ...option };
    return new Promise((resolve) => {
      Dialog.alert(data).then(() => {
        resolve();
      });
    });
  },
  confirm(option = {}) {
    const d = {
      title: '温馨提示',
      confirmButtonColor: mainColor,
      closeOnPopstate: false
    };
    const data = { ...d, ...option };
    return new Promise((resolve, reject) => {
      Dialog.confirm(data).then(() => {
        resolve();
      }).catch(() => {
        reject();
      });
    });
  }
};
const msgTypes = ['Success', 'Fail', 'Html'];
msgTypes.forEach((i) => {
  const type = i.toLocaleLowerCase();
  msg['showMsg' + i] = (message) => {
    Toast({ message, type, closeOnClick: true });
  };
});

export default msg;

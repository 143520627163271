
// status
const codes = {
  CODE_OK: 200, // 成功
  CODE_UNLOGIN: 201, // 未登陆 TODO: 待确认
  CODE_NO_AUTH: 401
};

const codeMessage = {
  [codes.CODE_OK]: '请求成功', // 作为示例，正常不会用到成功的信息
  [codes.CODE_UNLOGIN]: '未登陆'
};

export {
  codes,
  codeMessage
};

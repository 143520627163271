import store, { store2, mutationType } from '@/store';
import { STORAGE_TOKEN } from '@/config';
import { apiUser } from '@/api';
import { bridgeRelanch } from './utilsBridge';
import msg from './message';

/**
 * @description 开发环境登陆
 * 登陆后token进行存储localStorage
 */
export function appLoginDev() {
  const devParams = {
    userName: 'test1',
    password: '123456'
  };
  // return apiUser.devLogin(devParams).then(res => {
  //   appSetToken(res.token);
  //   appUpdateUserInfo();
  //   return res;
  // });
}

/**
 *
 * @description 更新用户信息
 */
export function appUpdateUserInfo(isAlwayResolve?) {
  store.dispatch('app/appUpdateUserInfo', isAlwayResolve);
}
export function setFamousIndex(index?) {
  store.dispatch('app/setFamousIndex', index);
}
/**
 *
 * @description 删除用户信息
 */
export function appRemoveUserInfo() {
  store.commit(`app/${mutationType.app.UPDATE_USER_INFO}`, null);
}

/**
 *
 * @description 本地取token，统一下入口
 */
export function appSetToken(token) {
  if (!token) {
    appGetToken();
  } else {
    store2.set(STORAGE_TOKEN, token);
  }
}
/**
 *
 * @description 本地取token，统一下入口
 */
export function appGetToken() {
  return store2.get(STORAGE_TOKEN);
}

/**
 *
 * @description 登陆未登陆或登陆过期
 * 清空个人信息，清空storage token
 */
export function appUnLoginHandler(code) {
  appRemoveUserInfo();
  store2.remove(STORAGE_TOKEN);
  // bridgeRelanch(code); // 重启小程序
}

<template>
  <div id="app">
    <div class="app-inner">
      <router-view />
    </div>
    <!-- <user-agree
      v-model="showPolicy"
      :first-open="true"
      title="隐私协议"
      :show-confirm="true"
    /> -->
  </div>
</template>

<script>
// import VConsole from 'vconsole';
// var vConsole = new VConsole();
import { initSDK } from '@/utils/src/utilsWeixin';
import * as auth from '@/utils/auth';
import * as tools from '@/utils/src/tools';
// import userAgree from '@/pages/login/components/userAgree';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'App',
  // components: {userAgree},
  data() {
    return {
      showPolicy: false
    };
  },
  computed: {
    ...mapGetters(['token', 'userInfo'])
  },
  created() {
    const vm = this;
    // this.setHomeTab('Home');
    if (window.plus) {
      vm.plusReady();
      // vm.getDeviceInfo();
    } else {
      if (tools.os.isWechat) {
        vm.initWeixinShare();
      } else {
        document.addEventListener('plusready', function() {
          if (window.plus) {
            vm.plusReady();
            // vm.getDeviceInfo();
          }
        });
      }
    }
    // 第一次登录
  },
  methods: {
    ...mapActions(['setHomeTab']),
    plusReady() {
      let _that = this;
      window.plus.navigator.setStatusBarBackground('#ffffff'); // 设置系统状态栏的背景颜色
      window.plus.navigator.setStatusBarStyle('dark'); // 可取值： "dark"：深色前景色样式（即状态栏前景文字为黑色），此时background建议设置为浅颜色； "light"：浅色前景色样式（即状态栏前景文字为白色），此时background建设设置为深颜色；
      console.log('setStatusBarBackground');
      window.plus.key.addEventListener('backbutton', () => {
        let _curPath = _that.$router.history.current;
        let _isOut = _curPath.meta.out;
        if (_isOut == 1) {
          this.$dialog
            .confirm({
              title: '提示',
              message: '确定退出应用吗？'
            })
            .then(() => {
              window.plus.runtime.quit();
            })
            .catch(() => {});
        } else {
          _that.$router.go(-1);
        }
      });
    },
    getDeviceInfo() {
      window.plus.device.getInfo({
        success: function(e) {
          console.log('device', JSON.stringify(e));
          auth.loc.set('device_uuid', e.uuid);
        },
        fail: function(e) {
          console.log('getDeviceInfo failed: ' + JSON.stringify(e));
        }
      });
    },
    initWeixinShare() {
      initSDK()
        .then(() => {
          console.log('initshare success');
        })
        .catch(err => {
          console.dir(err);
        });
    }
  }
};
</script>

<style lang="scss">
@import '@/styles/varHelper/_var.scss';
#app {
  height: 100%;
  overflow: hidden;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  background: rgb(249, 249, 249);
  .app-inner {
    height: 100%;
    overflow: hidden;
  }
}
</style>

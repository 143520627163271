
import { Component, Vue, Prop } from 'vue-property-decorator';
import ZmIcon from '../ZmIcon/index.vue';

@Component({
  name: 'AppHeader',
  components: {}
})
export default class App extends Vue {
  @Prop({ type: String }) title: string;
  @Prop({ type: Boolean, default: true }) showLeft;
  @Prop({ type: Boolean, default: true }) showBack;
  @Prop({ type: Boolean, default: true }) showRight;
  @Prop({ type: String, default: 'primary' }) bgType;
  get slots() {
    return this.$slots;
  }
  onClickBack() {
    try {
      this.$router.back();
    } catch (e) {
      if (this.$route.path !== '/index/lightcourse_web') {
        this.$router.replace('/index/lightcourse_web');
      }
    }
  }

  renderLeft(h) {
    let children = this.showBack
      ? [
          h('span', {
            class: 'back-png',
            on: {
              click: this.onClickBack
            }
          })
        ]
      : [];
    const _children = this.$scopedSlots.left ? this.$scopedSlots.left({}) : null;
    if (_children) {
      children = children.concat(_children);
    }

    return h('div', { class: 'zm-appHeader__left' }, children);
  }
  renderRight(h) {
    const children = this.$scopedSlots.right ? this.$scopedSlots.right({}) : null;

    return h('div', { class: 'zm-appHeader__right' }, children);
  }

  renderTitle(h) {
    const children = this.$scopedSlots.title ? this.$scopedSlots.title({}) : this.title;
    return h('div', { class: 'zm-appHeader__title zm-flex__1' }, children);
  }

  mounted() {}

  render(h) {
    const headerProperty = { class: 'zm-appHeader zm-flex is-align-middle' };
    const Title = this.renderTitle(h);
    const Left = this.showLeft ? this.renderLeft(h) : null;
    const Right = this.showRight ? this.renderRight(h) : null;

    if (this.bgType === 'primary') {
      headerProperty.class += ' is-bgPrimary';
    }

    return h('div', headerProperty, [Left, Title, Right]);
  }
}

import VConsole from 'vconsole';
import { projMode } from '@/config';
import qs from 'qs';
import decomposeUrl from './decomposeUrl';
export const os = (function(): any {
  var ua: string = navigator.userAgent,
    isWindowsPhone: boolean = /(?:Windows Phone)/i.test(ua),
    isSymbian: boolean = /(?:SymbianOS)/i.test(ua) || isWindowsPhone,
    isAndroid: boolean = /(?:Android)/i.test(ua),
    isFireFox: boolean = /(?:Firefox)/i.test(ua),
    isChrome: boolean = /(?:Chrome|CriOS)/i.test(ua),
    isIPhone: boolean = /(?:iPhone)/i.test(ua) && !isTablet,
    isTablet: boolean =
      /(?:iPad|PlayBook)/i.test(ua) || (isAndroid && !/(?:Mobile)/i.test(ua)) || (isFireFox && /(?:Tablet)/i.test(ua)),
    isPc: boolean = !isIPhone && !isAndroid && !isSymbian,
    isCellPhone: boolean = isIPhone || isSymbian || isAndroid || isWindowsPhone,
    isWechat: boolean = top.hasOwnProperty('WeixinJSBridge') || /(?:micromessenger)/i.test(ua),
    isAlipay: boolean = /(?:alipayclient)/i.test(ua),
    isQQ: boolean = /(?:\sqq\/)/i.test(ua);

  return {
    ua: ua,
    isWindowsPhone: isWindowsPhone,
    isSymbian: isSymbian,
    isAndroid: isAndroid,
    isFireFox: isFireFox,
    isChrome: isChrome,
    isIPhone: isIPhone,
    isTablet: isTablet,
    isPc: isPc,
    isCellPhone: isCellPhone,
    isWechat: isWechat,
    isAlipay: isAlipay,
    isQQ: isQQ
  };
})();

export function removeItem(arr: any[], item: any) {
  if (arr.length) {
    var index = arr.indexOf(item);
    if (index > -1) {
      return arr.splice(index, 1);
    }
  }
}
export function deep(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}
// 数组对象去重
export function deDupArray(array: any[], key: any = 'id') {
  const obj = {};
  const result = [];
  array = deep(array);
  const len = array.length;
  for (let i = len; i--; ) {
    const item = array[i];
    if (!obj[item[key]] && item[key]) {
      result.unshift(item);
      obj[item[key]] = true;
    }
  }
  return result;
}
// 对象循环
export function eachOwn(obj: any, fn: any) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      fn(obj[key], key, obj);
    }
  }
}
export const lazyComponent = (name, isIndex = true) => () => import(`@/pages/${name}${isIndex ? '/index' : ''}.vue`);
export const throttle = (fn, args = [], that = this, time = 300) => {
  fn._throttleID && clearTimeout(fn._throttleID);
  fn._throttleID = setTimeout(function() {
    // 执行函数
    fn.apply(that, args);
  }, time);
};
export const createScript = (obj = {}, el = 'head') => {
  const jsapi = document.createElement('script');
  console.log('dssfs');
  for (const i in obj) {
    jsapi[i] = obj[i];
  }
  document[el].appendChild(jsapi);
  return new Promise<void>(resolve => {
    jsapi.onload = () => {
      resolve();
    };
  });
};
export function randomNumberOne(min, max) {
  return Math.floor(Math.random() * (Number(max) + Number(min) + 1) + Number(min));
}
export const showDebug = () => {
  const vc = window.vc;
  if (!vc) {
    window.vc = new VConsole();
  }
};

export const showDebugBuyUrl = () => {
  const url = window.location.href;
  if (url.includes('debug=debug') || projMode !== 'production') {
    // showDebug();
  }
};
export const isNull = str => {
  return !str && typeof str !== 'undefined' && str !== 0;
};

export function param2Obj(url = window.location.search) {
  // url = url || window.location.search;
  const search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return qs.parse(search);
}
export function obj2Param(obj = {}) {
  return qs.stringify(obj);
}
export function handleSearchCodeUrl(url, exclude = ['code', 'state']) {
  let locObj = decomposeUrl(url);
  let params = { ...locObj.params };
  exclude.forEach(item => {
    delete params[item];
  });
  return `${locObj.origin}/?${qs.stringify(params)}${locObj.hash}`;
}
// 获取某一个值
export const getWidowValue = (key, target = window) => {
  return new Promise((resolve, reject) => {
    function getStatus(resolve) {
      setTimeout(() => {
        if (target[key]) {
          resolve(target[key]);
        } else {
          getStatus(resolve);
        }
      }, 200);
    }
    getStatus(resolve);
  });
};

export const firstToUpper = str => {
  return str.replace(/\b(\w)(\w*)/g, function($0, $1, $2) {
    return $1.toUpperCase() + $2.toLowerCase();
  });
};
export const getRandomArr = (arr, count) => {
  // 随机抽取
  var shuffled = arr.slice(0),
    i = arr.length,
    min = i - count,
    temp,
    index;
  while (i-- > min) {
    // 打乱数组
    index = Math.floor((i + 1) * Math.random());
    temp = shuffled[index];
    shuffled[index] = shuffled[i];
    shuffled[i] = temp;
  }
  return shuffled.slice(min);
};
// 打乱数组
export const shuffleArray = function(array) {
  var m = array.length,
    t,
    i;
  while (m) {
    i = Math.floor(Math.random() * m--);
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }
  return array;
};

<!-- zmBtn -->
<template>
  <van-button :class="zmClass" :round="round" :color="color | color($attrs.type)" v-bind="$attrs" v-on="$listeners">
    <slot />
  </van-button>
</template>

<script>
export default {
  name: 'ZmBtn',
  filters: {
    // type 的优先级大于 color
    color(v, type) {
      if (type) {
        return '';
      }
      return v;
    }
  },
  props: {
    color: {
      type: String,
      default() { return this.$varColor.mainColor || '#2fb3ff'; }
    },
    round: {
      type: Boolean,
      default: true
    },
    // w 白色 gray 灰色 yellow 黄色 gb 灰色边框
    t: {
      type: String

    }
  },
  data() {
    return {};
  },
  computed: {
    zmClass() {
      return {
        'zmbtn-w': this.t === 'w',
        'zmbtn-g': this.t === 'gray',
        'zmbtn-yellow': this.t === 'yellow',
        'zmbtn-gb': this.t === 'gb',
        'zmbtn-xf': this.t === 'xf'

      };
    }
  },
  methods: {
    // 初始化方法
    init() { }
  }
};
</script>
<style lang="scss" scoped>
.zmBtn {
  font-size: 0.3rem;
}
.zmbtn-w {
  color: $--color-primary !important;
  border: 1px solid #fff;
  background: #fff !important;
}

.zmbtn-g {
  color: $--color-text-regular !important;
  border: 1px solid $--color-text-secondary !important;
  background: $--color-text-secondary !important;
}
.zmbtn-yellow {
  color: #fff !important;
  border: 1px solid $--color-warning !important;
  background: $--color-warning !important;
}
.zmbtn-gb {
  color: $--color-text-primary !important;
  border: 1px solid #eee !important;
  background: #fff !important;
}
.zmbtn-xf {
  color: #fff !important;
//   background: url('~@/assets/img/xufa-btn-bg.png') no-repeat !important;

  background-size:100% 100% !important;
  border: none;

}
.zmBtn-border {
  border: 1px solid $bgColor !important;
}
</style>


<script>
import { Component, Vue, Prop,Watch } from 'vue-property-decorator';
import { isMiniprogram } from '@/utils';
import AppHeader from '../ZmAppHeader/index.vue';
import AppFooter from '../ZmAppFooter/index.vue';
// const isWxMini = process.env.VUE_APP_FLAG === 'development';
@Component({
  name: 'AppPage',
  components: {}
})
export default class App extends Vue {
  @Prop({ type: String }) title;
  @Prop({ type: Boolean,default: true}) showTitle;
  @Prop({ type: Boolean, default: true }) showHeader;
  @Prop({ type: Boolean, default: true }) showBack;
  @Prop({ type: [Boolean, Number], default: false }) showFooter;
  @Prop({ type: Boolean, default: true }) showHeaderLeft;
  @Prop({ type: Boolean, default: false }) showHeaderRight;
  @Prop({ type: [String,Number], default: '0' }) scrollTop;

  get hasSlotLeft() {
    return this.$slots.headerLeft;
  }
  get hasSlotRight() {
    return !!this.$slots.headerRight;
  }
  get navTitle() {
    return !this.showTitle ? '' : (this.title || this.$route.meta.title) ;
  }
  // get isWxMini() {
  //   return isMiniprogram();
  // }
  @Watch('scrollTop')
  onScrollTopChange(val, oldVal) {
    const el = this.$refs.container;
    const res = (+this.scrollTop) - (+el.clientHeight);
    if (el && res > 0) {
      el.scrollTop = res;
    }
  }
  onClickLeft() {
    this.$emit('click-left');
  }
  renderHeader(h) {
    const headerProperty = {
      class: 'zm-appPage__header',
      props: { title: this.navTitle, showLeft: this.showHeaderLeft, showRight: this.showHeaderRight,showBack: this.showBack }
    };
    if (this.$slots.headerTitle) {
      headerProperty.scopedSlots = headerProperty.scopedSlots || {};
      headerProperty.scopedSlots.title = () => this.$slots.headerTitle;
    }
    if (this.$slots.headerLeft) {
      headerProperty.scopedSlots = headerProperty.scopedSlots || {};
      headerProperty.scopedSlots.left = () => this.$slots.headerLeft;
    }

    if (this.$slots.headerRight) {
      headerProperty.scopedSlots = headerProperty.scopedSlots || {};
      headerProperty.scopedSlots.right = () => this.$slots.headerRight;
    }

    return h(AppHeader, headerProperty);
  }
  renderFooter(h, type) {
    const headerProperty = { class: 'zm-appPage__footer', props: { type: type } };
    return h(AppFooter, headerProperty);
  }

  mounted() {}

  render(h) {
    const classes = ['zm-appPage'];
    const property = { class: classes };

    let Header = null,
      Footer = null;
    if (this.showHeader && this.$slots.header) {
      Header = this.$slots.header;
      if (Header instanceof Array && Header.length) {
        Header.forEach((o) => {
          o.data = o.data || {};
          o.data.staticClass = o.data.staticClass ? o.data.staticClass + ' zm-appPage__header' : 'zm-appPage__header';
        });
      }
    } else if (this.showHeader) {
      Header = this.renderHeader(h);
    }

    if (this.showFooter && this.$slots.footer) {
      Footer = this.$slots.footer;
      if (Footer instanceof Array && Footer.length) {
        Footer.forEach((o) => {
          o.data = o.data || {};
          o.data.staticClass = o.data.staticClass ? o.data.staticClass + ' zm-appPage__footer' : 'zm-appPage__footer';
        });
      }
    } else if (this.showFooter) {
      Footer = this.renderFooter(h, this.showFooter === 1 ? 1 : 2);
    }

    if (Header) {
      classes.push('is-hasHeader');
    }
    if (Footer) {
      classes.push('is-hasFooter');
    }

    const Container = h('div', { class: 'zm-appPage__container',ref: 'container' }, [this.$slots.default]);

    return h('div', property, [Header, Container, Footer]);
  }
}
</script>

<style lang="scss" scoped>
@include b(appPage) {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  @include when(hasHeader) {
    padding-top: $--header-height;
  }
  @include when(hasFooter) {
    padding-bottom: $--footer-height-pd;
  }
  @include e(container) {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
  }

  @include e(header) {
    top: 0;
  }
  @include e(footer) {
    bottom: 0;
    box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.1);

    // 放在底部的分享栏
    /deep/ &.zm-shareBar {
      height: $--footer-height;
      line-height: $--footer-height;
      text-align: center;
    }
  }

  &__header,
  &__footer {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
  }
}
</style>

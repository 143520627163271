const prefix = 'COURSE_WEB_APP';
/**
 * app存储key值
 */
export const STORAGE_TOKEN = 'COURSE_WEB_APP_TOKEN';
export const STORAGE_USER_INFO = 'COURSE_WEB_APP.USERINFO';
export const STORAGE_APP_INFO = 'COURSE_WEB_APP.APP_INFO';
export const STORAGE_FIRST_LOGIN = 'COURSE_WEB_APP.FIRST_LOGIN';
export const STORAGE_FIRST_OPEN = 'COURSE_WEB_APP.FIRST_OPEN';

/**
 * app全站常量
 */
export const APP_API_PREFIX = process.env.VUE_APP_API_PREFIX.toString(); // api请求
export const APP_TIMEOUT = 20000;
/**
 * course存储key
 */

export const STORAGE_CURRENT_BOOK = `${prefix}.CURRENT_BOOK`;
export const STORAGE_HOME_TAB = `${prefix}.HOME_TAB`;
export const STORAGE_UNIT_INDEX = `${prefix}.UNIT_INDEX`;
export const STORAGE_PAGE_CONTENT = `${prefix}.PAGE_CONTENT`;

/**
 * listen key
 */
export const LOC_CUSTOM_SELECT = `${prefix}.LISTEN_DATA`;
export const LOC_LISTEN_BOOK = `${prefix}.LISTEN_BOOK`;
export const LOC_LISTEN_BOOK_UNIT = `${prefix}.LISTEN_BOOK_UNIT`;

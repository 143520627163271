'use strict';

import request from '../request';
const _model = '/book';
export default {
  getAppInfo(params) {
    return request.get(`/app/info`, params);
  },
  // 获取出版社
  getPress(params) {
    return request.get(`${_model}/press`, params, {});
  },
  // 开发环境登陆
  getList(params) {
    return request.get(`${_model}/page`, params);
  },
  getUnitList(params) {
    return request.get(`/unit/page`, params);
  },
  getFirstUnitContent(params) {
    return request.get(`/first/unit/content`, params);
  },
  getUnitContent(params) {
    return request.get(`/unit/content`, params);
  },
  getUnitWord(params) {
    return request.get(`/page/content`, params);
  }
};

import NProgress from 'nprogress';
import 'nprogress/nprogress.css'; // progress bar style
NProgress.configure({ showSpinner: false });
let time = null;

 const start = () => {
    time && clearTimeout(time);
    time = setTimeout(() => {
        NProgress.start();
    }, 100);
};

 const done = () => {
    time && clearTimeout(time);
    NProgress.done();
};

export default {
    start,
    done
};

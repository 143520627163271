<!-- ZmImg -->
<template>
  <van-image v-bind="$attrs" :fit="fit" :lazy-load="lazyLoad">
    <div slot="error" class="w100p h100p">
      <img class="noimg" v-if="eIcon" :src="eIcon">
      <div v-else class="noimg bgn">加载失败</div>
    </div>
  </van-image>
</template>

<script>
import Vue from 'vue';
import { Lazyload } from 'vant';
Vue.use(Lazyload);
const types = {
  default: { img: require('@/assets/img/default/default-error.png') }
//   banner: { img: require('@/assets/img/noImg/banner.png') }
};
export default {
  name: 'ZmImg',
  props: {
    fit: {
      type: String,
      default: 'cover'
    },
    lazyLoad: {
      type: Boolean,
      default: true
    },
    errorIcon: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
    };
  },
  computed: {
    eIcon() {
      if (this.errorIcon) { return this.errorIcon; }
      const type = this.type;
      const item = types[type] || {};
      return item.img || '';
    }
  },
  methods: {
    // 初始化方法
    init() {}

  }
};
</script>
<style lang="scss" scoped>
.noimg{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bgn{
  width: 100%;
  height: 100%;
  background: #eee !important;
}
</style>


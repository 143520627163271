<!-- zmPopupPick 底部弹出选择 -->
<template>
  <div>
    <zm-popup
      v-model="popup_show"
      position="bottom"
      :close-on-click-overlay="true"
      :closeable="false"
    >
      <van-picker
        v-if="popup_show"
        :title="title"
        :default-index="defaultIndex"
        show-toolbar
        :value-key="lable"
        :columns="columns"
        @cancel="cancel"
        @confirm="onConfirm"
      />
    </zm-popup>
  </div>
</template>

<script>
/**
 * @confirm 选中
 */
export default {
  name: 'ZmPopupPick',
  props: {
    // 使用 show.sync
    show: {
      required: true,
      type: Boolean,
      default: () => {
        return false;
      }
    },
    // 列表数据
    columns: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    },

    // 展示的 字段名
    lable: {
      type: String,
      default: 'name'
    },

    // 所选的id
    value: {
      type: [String , Number],
      default: ''
    },

    // 所选值的key
    valueKey: {
      type: String,
      default: 'value'
    },

    // 标题名
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      popup_show: this.show
    };
  },
  computed: {
    defaultIndex() {
      const valueKey = this.valueKey;
      if (!valueKey) {
        return 0;
      }
      const index = this.columns.findIndex((i) => i[valueKey] === this.value);
      return index !== -1 ? index : 0;
    }
  },

  watch: {
    show(v) {
      this.popup_show = v;
    },

    popup_show(v) {
      this.$emit('update:show', v);
    }
  },
  methods: {
    // 初始化方法
    init() {},

    cancel() {
      this.popup_show = false;
    },

    onConfirm(v) {
      this.$emit('confirm', v);
      this.popup_show = false;
    }
  }
};
</script>
<style lang="scss" scoped></style>


export function getInstaller(type: 'component' | 'filter' | 'directive', option: any) {
   return {
    installed: false,
    install(Vue: any) {
      if (this.installed) { return; }
      for (const key in option) {
        if (option.hasOwnProperty(key)) {
          Vue[type](key, option[key]);
        }
      }
      this.installed = true;
    }
  };
}


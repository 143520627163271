import { codes, codeMessage } from './requestCode';
// import { appUnLoginHandler } from '../../utils/src/utilsApp';
import { alertToast, alertConfirm } from '@/utils/src/utilsAlert';
import router from '@/router';
import * as path from '@/router/routes/routePath';
// wx.miniProgram.postMessage({
//   data: {
//     type: 'unlogin',
//     message: '未登陆'
//   }
// });
/**
 *
 * @description 处理返回结果
 * @param {object} response
 * @param {object} options
 * @returns Promise
 */

function handleResponse(response: any, options) {
  const { status, data } = response;
  const { preHandler } = options;
  // preHandler如果返回Promise，则可以自行决定该请求是resove或reject状态
  if (typeof preHandler === 'function') {
    const preHandlerResult = preHandler(response);
    if (preHandlerResult instanceof Promise) {
      return preHandlerResult;
    }
  }
  // console.log(response);
  // const wxData = {
  //   name: 'unlogin',
  //   message: 'unlogin'
  // };
  // wx.miniProgram.navigateTo({ url: '../index/index?data=' + JSON.stringify(wxData) });

  // TODO
  //  && data.code === `${codes.CODE_OK}`
  if (status == codes.CODE_OK) {
    return Promise.resolve(data);
  } else {
    _handleError(response);
    return Promise.reject(response);
  }
}

/**
 * @description 请求过程的严重错误
 * @param e
 */
function handleBlockError(e) {
  alertToast('服务器错误');
  throw e;
}

// 对xss做简单处理
const xssRegExp = /<[\s\S]*>/;
function handleXSS(obj) {
  /* eslint-disable no-unused-vars */
  try {
    const objStr = JSON.stringify(obj);
    const xss = objStr.toLocaleLowerCase().match(xssRegExp) || null;
    return xss;
  } catch (e) {
    return null;
  }
  /* eslint-enable no-unused-vars */
}

/**
 * @description 接口出错的处理，如登录失效时清空认证信息；其他错误弹出提示信息等，其中提示信息可以前端自定义
 * @param response
 */
function _handleError(response) {
  const { status, data } = response;

  // console.log(response);
  let _message = null;
  // 根据具体需要处理的情况增加不同判断分支
  // data.code 是字符串 此处用 ==
  switch (status) {
    case 404:
      _message = '404,错误请求';
      break;
    case 401:
      alertConfirm({
        title: '系统提示',
        message: '您还未登录/登录失效，是否去登录？'
      }).then(() => {
        router.push({ path: '/login', query: { redirect: router.currentRoute.fullPath } });
      });
      return Promise.reject(data);
      break;
    case 403:
      _message = '禁止访问';
      break;
    case 408:
      _message = '请求超时';
      break;
    case 500:
      _message = '服务器内部错误';
      break;
    case 501:
      // return Promise.reject(data);
      alertConfirm({
        title: '系统提示',
        message: `${data.message}，是否去购买?`
      }).then(() => {
        router.push({
          path: path.PATH_PAY,
          query: { redirect: router.currentRoute.fullPath }
        });
      });
      return Promise.reject(data);
      // _message = '功能未实现';
      break;
    case 503:
      _message = '服务不可用';
      break;
    case 504:
      _message = '网关错误';
      break;
    default:
      _message = '未知错误';
  }
  alertToast({
    message: data.message || codeMessage[status] || _message || '服务器出错，请稍后再试'
    // duration: 3000
  });
  // 未登陆判断
  return Promise.reject(data);
}

export { handleResponse, handleBlockError, handleXSS, _handleError };

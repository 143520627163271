/*
 * @Author: qiahao
 * @Date:  2020-08-04 17:21
 * @Last Modified by: ganqianzhao
 * @Last Modified time: 2021-01-14 20:35:54
*/


import '@/styles/common.scss';
import Vue from 'vue';
import { vantUI } from './vantUi';
import Component from 'vue-class-component';
import * as utils from '@/utils';
import msg from '@/utils/src/message';
import commonComponents from '@/commons';
// import filters from '@/filters';
import '@/icons';
import { eachOwn } from '@/utils';
eachOwn(commonComponents, (item, key) => {
  Vue.component(key, item);
});
Vue.prototype.$utils = utils;
// Vue.use(filters);
Vue.prototype.$msg = msg;

vantUI(Vue);
// import 'amorepay/dist/style.css';

Vue.config.productionTip = false;

Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);

// 全局方法添加
// Vue.prototype.xxx = xxx;
